<template>
  <v-container fluid>
    <portal to="app-header">
      <template>
       {{ $t('traceabilityReport.title') }}
      </template>
    </portal>
    <v-fade-transition mode="out-in">
      <traceability></traceability>
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Traceability from './Traceability.vue';

export default {
  name: 'Index',
  components: {
    Traceability,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('traceabilityReport', ['lastRefreshedAt', 'loading']),
    ...mapState('helper', ['selectedAsset', 'assetKey']),
  },
  async created() {
    const appId = localStorage.getItem('appId');
    await this.getSchemaAndLabel(appId);
  },
  methods: {
    ...mapActions('traceabilityReport', ['fetchPlans']),
    ...mapActions('helper', ['getSchemaAndLabel']),
    ...mapMutations('traceabilityReport', []),
  },
  watch: {},
};
</script>
