<template>
  <v-layout
    column
    align-center
    justify-center
  >
    <v-flex
      xs12
      class="pa-2"
    >
      <img
        src="../assets/upload.svg"
        class="responsive-img"
        alt="Not found"
      >
    </v-flex>
    <v-flex xs12>
      <span class="headline text-none black--text">
        {{ $t('noData') }}
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'NoResult',
};
</script>

<style scoped>
.responsive-img {
  height: calc(100vh - 300px);
}
</style>
