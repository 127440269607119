<template>
  <v-flex>
    <template v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              autofocus
              outlined
              dense
              v-model="productCode"
              required
              ref="productInput"
              :label="$t('traceabilityReport.productCode')"
              @change="setTraceabilityData(null)"
              @keyup.enter="fetchTraceabilityData(productCode)"
              @keyup.tab="fetchTraceabilityData(productCode)"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="text-none"
              color="primary"
              @click="fetchTraceabilityData(productCode)"
              :disabled="!productCode"
            >
              {{$t('traceabilityReport.search')}}
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="text-none"
              color="error"
              @click="reset"
              :disabled="!productCode"
            >
              {{$t('traceabilityReport.reset')}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn
              class="text-none"
              color="success"
              @click="generateReport"
              :disabled="traceabilityData === null"
            >
              <v-icon left>mdi-file-pdf</v-icon>
              {{$t('traceabilityReport.download')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="productCode && traceabilityData !== null">
      <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :filename="fileName()"
      :paginate-elements-by-height="2000"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :manual-pagination="false"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
      >
        <section class="mt-2" slot="pdf-content">
          <v-card width="100%">
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <img
                    :src="`/assets/logo/shopworx-light.png`"
                    contain
                    height="52"
                  />
                </v-col>
                <v-col cols="2" >
                  <div style="margin-top: 20px;">
                    <b> {{ $t('traceabilityReport.productCode') }} </b>: {{ productCode }}
                  </div>
                </v-col>
                <v-col cols="3" >
                  <div style="margin-top: 20px;">
                    <b> {{ $t('traceabilityReport.part') }} </b>: {{ productCode }}
                  </div>
                </v-col>
                <v-col cols="3" >
                  <div style="margin-top: 20px;">
                    <b> {{ $t('traceabilityReport.model') }} </b>: {{ traceabilityData.model }}
                  </div>
                </v-col>
                <v-col cols="2" >
                  <div style="margin-top: 20px;">
                    <b> {{ $t('traceabilityReport.customer') }}
                    </b>: {{ traceabilityData.customer }}
                  </div>
                </v-col>
              </v-row>
              <v-row v-for="(value, key) in traceabilityData.childpartdetails" :key="key">
                <div class="machine pa-2 mt-3"> {{ key }} </div>
                <v-data-table
                  :headers="headers"
                  :items="value"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </vue-html2pdf>
    </v-card>
    <v-card v-if="productCode && !traceabilityData">
      <no-traceability/>
    </v-card>
  </v-flex>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import VueHtml2pdf from 'vue-html2pdf';
import NoTraceability from './NoTraceability.vue';

export default {
  name: 'Traceability',
  components: {
    VueHtml2pdf,
    NoTraceability,
  },
  data() {
    return {
      productCode: null,
      headers: [
        {
          text: 'Child Part Name',
          align: 'start',
          sortable: false,
          value: 'childpartname',
        },
        { text: 'Child Part Number', value: 'childpartnumber' },
        { text: 'Child Part Code', value: 'childpartcode' },
        { text: 'Created At', value: 'createdTimestamp' },
        // { text: 'Shift', value: 'shiftName' },
        { text: 'Created By', value: 'operatorname' },
        { text: 'Cost Per Piece', value: 'costperpiece' },
        { text: 'No. of Pieces', value: 'quantity' },
      ],
    };
  },
  computed: {
    ...mapState('traceabilityReport', ['loading', 'traceabilityData']),
    notFoundIllustration() {
      return this.$vuetify.theme.dark
        ? 'not-found-dark'
        : 'not-found-light';
    },
  },
  methods: {
    ...mapActions('traceabilityReport', ['fetchTraceabilityData']),
    ...mapMutations('traceabilityReport', [
      'setTraceabilityData',
    ]),
    fileName() {
      return `${this.productCode}-traceability-report`;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload({ html2pdf, pdfContent }) {
      await html2pdf().set({
        margin: [10, 0, 10, 0],
        filename: this.fileName(),
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pageBreak: { mode: 'css', after: '.break-page' },
      }).from(pdfContent).toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            // pdf.text(`Page ${i} of ${totalPages}`);
          }
        })
        .save();
    },
    reset() {
      this.productCode = null;
      this.setTraceabilityData(null);
    },
  },
};
</script>

<style>
.machine{
  background: #53af50;
  color: #fff;
  width: 100%;
  font-weight: bold;
}
.inputClass{
  height: 45px;
  margin: 0px;
}
.vue-html2pdf .layout-container {
  position: unset !important;
  background: #fff !important;
  width: 78vw !important;
}

.stageClass{
  background: #354493 !important;
  color: #fff;
}
</style>
